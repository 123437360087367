import React, { useContext } from 'react';
import { PageContext } from '@/context/PageContext';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Heading4 } from '@latitude/heading';

import Layout from '@/components/layout';
import AchPageHeaderSection from './_ach-page-header';
import LowRateMicrositeHeader from './../_low-rate-header';
import HeroBanner from '@/components/lab-components/HeroBanner';

function AchDetailsPage(props) {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/low-rate/addcard/details/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your Latitude Low Rate Mastercard"
          />
          <title>Additional Card Holder Request | Low Rate Mastercard</title>
        </Helmet>

        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <AchPageHeaderSection />
        )}
        <LowRateMicrositeHeader />

        <Section>
          <Heading4 css={{ 
            color: '#1964B4',
            marginTop: '30px'
             }}>
            Nominate an Additional Cardholder today
          </Heading4>
          <div
            css={{ clear: 'both', margin: '10px 0' }}
            className="lfs-iframe-eform"
          >
            <iframe
              css={{
                width: '100%',
                minHeight: '800px',
                border: 'none',
                padding: '10px'
              }}
              title="Request an Additional Card Holder"
              src="https://eform.latitudefinancial.com.au/eform/Page.do?eFormName=ACH_lowrate"
            />
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export default AchDetailsPage;
